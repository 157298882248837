<template>
  <div class="col-6">
    <div class="card card--profile-tasks">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="clearfix">
              <h3 class="float-start">
                <a data-action="collapse"><i
                  data-feather="activity"
                  class="w-icon-24"
                /> Log of actions</a>
              </h3>
              <span class="float-end">
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="View all timeline"
                ><a
                  href="#"
                  class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                ><i data-feather="eye" /></a></span>
              </span>
            </div>

            <hr>
            <div class="table-responsive-sm">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Hour</th>
                    <th>Source</th>
                    <th class="text-end">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>24 Jan, 2022</td>
                    <td>09:17h</td>
                    <td>Logout</td>
                    <td class="text-end" />
                  </tr>
                  <tr>
                    <td>24 Jan, 2022</td>
                    <td>09:01h</td>
                    <td>Login</td>
                    <td class="text-end" />
                  </tr>
                  <tr>
                    <td>23 Jan, 2022</td>
                    <td>17:11h</td>
                    <td>Add publication<br><small>Title publication</small></td>
                    <td class="text-end">
                      <a
                        href="#"
                        class="btn btn-icon btn-light-secondary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Go to source"
                      ><i data-feather="eye" /></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
