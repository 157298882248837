<template>
  <div class="col-4">
    <div class="card card--profile-alerts">
      <div class="card-body">
        <div class="row">
          <div class="card--profile-alerts-alarms">
            <h3>Notifications</h3>
            <hr>
            (listado alarmas)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    // await this.$store.dispatch('users/fetchUserGrants', this.loggedUser.id)
  },
}
</script>

<style>

</style>
