<template>
  <div class="col-12">
    <div class="card card--profile-calendar">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6 card--profile-alerts-calendar">
            <h3><i
              data-feather="calendar"
              class="w-icon-24"
            /> Calendar</h3>
            <!-- mostramos actividaes, viajes y tareas (tasks) cada uno en un color diferente -->
            <hr>
            <!-- Full calendar start -->
            <section>
              <div class="app-calendar overflow-hidden border">
                <div class="row g-0">

                  <!-- Calendar -->
                  <div class="col position-relative">
                    <div class="card shadow-none border-0 mb-0 rounded-0">
                      <div class="card-body pb-0">
                        <FullCalendar
                          ref="calendar"
                          :options="calendarOptions"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- /Calendar -->
                  <div class="body-content-overlay" />
                </div>
              </div>
            </section>
            <!-- Full calendar end -->
          </div>
          <div class="col-sm-6 card--profile-alerts-alarms">
            <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12">
                    <h3 class="content-header-title float-start mb-0">
                      <i
                        data-feather="bell"
                        class="w-icon-24"
                      />
                      Alarms
                    </h3>
                  </div>
                </div>
              </div>
              <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
                <div class="breadcrumb-right">
                  <a
                    class="btn btn-icon btn-secondary"
                    @click="createAlert({})"
                  ><i data-feather="plus" /></a>
                </div>
              </div>
            </div>
            <hr>
            <template v-if="loading">
              <div
                class="loading custom-loading"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
            </template>
            <template v-else>
              <div
                v-for="alert in realAlerts"
                :key="alert.id"
                class="profile__important__notification profile__important__notification--list"
              >
                <div class="row">
                  <div class="col-10">
                    <span class="text-info"><i data-feather="bell" /></span> <strong>{{ alert.title }}</strong>: <span
                      class="no-linebreak"
                      v-html="alert.message"
                    />
                    <p class="text-muted">
                      <small>{{ alert.date }}</small>
                    </p>
                  </div>
                  <div class="col-2 text-end">
                    <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Go to the notification"
                    >
                      <a
                        class="btn bg-light-secondary"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasReadNotification"
                        aria-controls="offcanvasRight"
                        @click="$emit('showAlert', alert)"
                      >
                        <i data-feather="link" />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <CreateAlert>
        <template #title>
          Create {{ !alert.created ? 'alarm' : 'event' }}
        </template>
        <template #create-alert>
          <div class="offcanvas-body">
            <div class="mb-1 offcanvas-body--view--title">
              <p class="mb-0">
                Date
              </p>
              <date-picker
                v-if="!alert.created"
                v-model="alert.begin_date"
                format="D MMM Y HH:mm:ss"
                class="w-100"
                type="datetime"
                value-type="format"
                :show-hour="true"
                :show-minute="true"
                :disabled-date="disabledRange"
              />
              <date-picker
                v-else
                v-model="alert.begin_date"
                format="YYYY-MM-DD HH:mm:ss"
                class="w-100"
                type="datetime"
                value-type="format"
                :show-hour="true"
                :show-minute="true"
                :disabled-date="disabledRange"
              />
            </div>
            <hr>
            <div class="mb-1">
              <label
                for="areaseniorcall"
                class="form-label"
              >Title</label>
              <input
                v-model="alert.title"
                class="form-control"
                type="text"
              >
            </div>
            <div class="mb-1">
              <label
                for="areaseniorcall"
                class="form-label"
              >Message</label>
              <quill-editor
                v-model="alert.message"
              />
            </div>
            <div class="mb-1">
              <label
                for="areaseniorcall"
                class="form-label"
              >
                Attached file
              </label>
              <div
                class="input-group custom-file-button"
              >
                <label
                  class="input-group-text"
                  for="formFile"
                >Select file</label>
                <input
                  id="formFile"
                  :ref="`file-alarm`"
                  class="form-control"
                  type="file"
                  @change="uploadFile('file-alarm')"
                >
              </div>
            </div>
            <div
              v-if="alert.created"
              class="mb-1"
            >
              <label
                for="areaseniorcall"
                class="form-label"
              >Type</label>
              <v-select
                v-model="alert.type"
                label="label"
                :options="[
                  {label: 'Task', value: 1},
                  {label: 'Alarm', value: 2},
                ]"
                :get-option-key="option => option.value"
              />
            </div>
            <div class="mb-1">
              <div class="form-check form-check-success form-switch d-flex align-items-center">
                <input
                  id="sendemail"
                  v-model="alert.send_mail"
                  type="checkbox"
                  value="View Archived"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="sendemail"
                >
                  <small class="ms-1">Send alert by mail too</small>
                </label>
              </div>
            </div>
          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              v-if="!sending"
              type="button"
              class="btn btn-dark mb-1 d-grid w-100"
              @click="submit"
            >
              Create
            </button>
            <button
              v-if="sending"
              class="btn btn-dark mb-1 w-100"
              type="button"
              disabled=""
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              <span class="ms-25 align-middle">Creating...</span>
            </button>
          </div>
        </template>
      </CreateAlert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import CreateAlert from '@/views/back/partials/offcanvas/CreateAlert.vue'
import 'vue2-datepicker/index.css'
import DatePicker from 'vue2-datepicker'

export default {
  components: {
    FullCalendar,
    CreateAlert,
    DatePicker,
  },
  data() {
    return {
      disabledBefore: new Date(),
      alert: {},
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        dateClick: this.createAlert,
        eventClick: this.showAlert,
        datesSet: this.loadAlerts,
        eventClassNames: this.eventClassNamesCallback,
        events: [],
        eventTimeFormat: { // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        },
      },
      sending: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      alerts: 'alerts/alerts',
      loading: 'alerts/loading',
      filters: 'filters/filters',
    }),
    realAlerts() {
      return this.alerts.filter(e => e.type.id === 2)
    },
  },
  watch: {
    alerts() {
      this.calendarOptions.events = this.alerts.map(alert => {
        const data = {
          title: alert.title,
          date: alert.unformatted_date,
          alert,
        }

        return data
      })
    },
  },
  async mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 100)
  },
  methods: {
    eventClassNamesCallback({ event }) {
      if (event.extendedProps.alert.type.id === 1) {
        if (event.extendedProps.alert.done) {
          return 'done' // Class for showing it green
        }

        return 'pending' // Class for showing it red
      }

      if (event.extendedProps.alert.type.id === 2) {
        return 'event-type-2' // Class for type_id 2
      }

      return null
    },
    getDates() {
      const calendarApi = this.$refs.calendar.getApi()
      return {
        begin_date: calendarApi.view.activeStart,
        end_date: calendarApi.view.activeEnd,
        user_id: this.$route.params.id,
      }
    },
    async submit() {
      this.sending = true
      if (this.$route.params.id || this.$route.query.prefilterUser) {
        this.alert.users = [{ id: (this.$route.params.id ?? this.$route.query.prefilterUser) }]
      }
      await this.$store.dispatch('alerts/create', this.alert)
      const dates = this.getDates()
      this.$store.dispatch('modals/toggleCreateItem', false)
      this.alert = {}
      await this.$store.dispatch('alerts/fetch', dates)
      this.sending = false
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    createAlert(arg) {
      if (!arg.dateStr) {
        this.$store.dispatch('modals/toggleCreateItem', true)
        this.alert = {
          created: !!arg.dateStr, begin_date: arg.dateStr, end_date: arg.dateStr, type: { label: 'Alarm', value: 2 },
        }
      } else {
        const clickedDate = this.getDateWithoutTime(arg.date)
        const nowDate = this.getDateWithoutTime(new Date())
        if (clickedDate >= nowDate) {
          this.$store.dispatch('modals/toggleCreateItem', true)
          this.alert = { created: !!arg.dateStr, begin_date: `${arg.dateStr} 00:00:00`, end_date: arg.dateStr }
        } else {
          this.$toastr.error('Please, select another day', 'Can\'t create notifications in the past')
        }
      }
    },
    disabledRange(date) {
      return date <= new Date()
    },
    getDateWithoutTime(dt) {
      dt.setHours(0, 0, 0, 0)
      return dt
    },
    showAlert(arg) {
      const { alert } = arg.event.extendedProps
      this.$emit('showAlert', alert)
    },
    async uploadFile(name) {
      const file = this.$refs[name].files[0]

      const fileData = {
        name: file.name,
        original_name: file.name,
        description: '',
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
        url: URL.createObjectURL(file),
        aux: name,
        user_id: this.loggedUser.id,
      }

      this.alert.file = fileData
    },
    async loadAlerts(arg) {
      const dates = {
        begin_date: arg.startStr,
        end_date: arg.endStr,
        per_page: 500,
        researcher: { id: this.$route.params.id ?? this.$route.query.prefilterUser },
      }

      await this.$store.dispatch('alerts/fetch', dates)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
  },
}
</script>
