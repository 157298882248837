<template>
  <div
    v-if="items && items.length > 0"
    class="col-6"
  >
    <div class="card card--profile-tasks">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="clearfix">
              <h3 class="float-start">
                <a data-action="collapse"><i
                  data-feather="globe"
                  class="w-icon-24"
                /> Ongoing trips</a>
              </h3>
            </div>
            <hr>
          </div>
        </div>
        <div
          v-if="loading"
          class="loading position-relative"
        >
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
        <div
          v-else
          class="table-responsive"
        >
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th
                  v-if="loggedUser.roles.includes('super-admin')"
                >Researcher</th>
                <th>Type</th>
                <th>Begin date</th>
                <th>End date</th>
                <th>Country</th>
                <th>TSS</th>
                <th>Insurance</th>
                <th>Address</th>
                <th>Institution</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="index"
              >
                <td v-if="loggedUser.roles.includes('super-admin')">{{ item.user ? item.user.user_name : '--' }}</td>
                <td>{{ item.type ? item.type : '--' }}</td>
                <td>{{ item.begin_date ? item.begin_date : '--' }}</td>
                <td>{{ item.end_date ? item.end_date : '--' }}</td>
                <td>{{ item.country ? item.country : '--' }}</td>
                <td><span :class="`badge bg-light-${item.tss ? 'success' : 'danger'}`">{{ item.tss ? 'Yes' : 'No' }}</span></td>
                <td><span :class="`badge bg-light-${item.insurance ? 'success' : 'danger'}`">{{ item.insurance ? 'Yes' : 'No' }}</span></td>
                <td>{{ item.address ? item.address : '--' }}</td>
                <td>{{ item.place ? item.place : '--' }}</td>
                <td class="text-end">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                      <b-dropdown-item @click="$router.push({name: item.route, params: { id: item.origenable_id }})">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View activity
                      </b-dropdown-item>
                      <!-- <div class="dropdown-divider" />
                      <b-dropdown-item>
                        <i
                          class="me-50"
                          data-feather="message-square"
                        /> Change request
                      </b-dropdown-item> -->
                    </div>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Vue from 'vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      userId: this.$route.params.id,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      items: 'tssManagement/items',
      loggedUser: 'auth/admin',
      user: 'users/user',
    }),
  },
  async mounted() {
    this.reloadPage()
  },
  methods: {
    async reloadPage() {
      this.loading = true
      if (this.userId) {
        await this.$store.dispatch('tssManagement/filter', { researcher: { id: this.userId }, currently: true })
      } else {
        if(this.loggedUser.roles.includes('super-admin')) {
          await this.$store.dispatch('tssManagement/filter', { currently: true })
        } else {
          await this.$store.dispatch('tssManagement/filter', { researcher: { id: this.loggedUser.id }, currently: true })
        }
      }
      this.loading = false
      // eslint-disable-next-line no-undef
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async onSearch(search) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch('users/fetchFakeUsers', search.name)
      }
    },
  },
}
</script>

<style>

</style>
