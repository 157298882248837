<template>
  <div
    :class="`col-${colSize}`"
  >
    <div class="card">
      <div
        v-b-toggle="`collapseStats`"
        class="card-header"
      >
        <h3
          :id="`field-1`"
          class="cart-title"
        >
          <a data-action="collapse">
            {{ fieldAux.label ? fieldAux.label : fieldAux.name }}
            <strong
              v-if="currentYear"
              class="badge bg-light-secondary scientific-year"
            >{{ currentYear }}</strong>
          </a>
        </h3>
        <b-tooltip
          :key="`field-custom-1`"
          placement="topleft"
          boundary="document"
          triggers="hover"
          :target="`field-1`"
          :title="fieldAux.tooltip"
        />
        <div class="heading-elements">
          <ul class="list-inline mb-0">
            <li>
              <a data-action="collapse"><i data-feather="chevron-down" /></a>
            </li>
          </ul>
        </div>
      </div>
      <b-collapse
        :id="`collapseStats`"
        v-model="isToggled"
      >
        <div
          class="card-content"
        >
          <div
            v-if="loading"
            class="loading position-relative"
          >
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
          <div
            v-else
            class="row"
          >
            <CardStat
              v-for="(stat, index) in scientificStats"
              :key="index"
              :type="index"
              :num="stat.num"
              :show-all="showAllStats"
              :current-year="currentYear"
            />
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse, BTooltip } from 'bootstrap-vue'
import CardStat from './CardStat.vue'

export default {
  components: {
    BCollapse,
    BTooltip,
    CardStat,
  },
  props: {
    userId: { type: Number, required: false, default: () => null },
    yearBetween: { type: Object, required: true, default: () => {} },
    field: { type: Object, required: false, default: () => null },
    currentYear: { type: [String, Number], required: false, default: () => new Date().getFullYear() },
    showAllStats: { type: Boolean, required: false, default: () => true },
    colSize: { type: Number, required: false, default: () => 12 },
    setDefaultToggled: { type: Boolean, required: false, default: () => null },
  },
  data() {
    return {
      loading: true,
      isToggled: true,
      defaultOrder: [
        {
          name: 'publications',
          checked: true,
          order: 1,
        },
        {
          name: 'grants',
          checked: true,
          order: 2,
        },
        {
          name: 'amount_of_grant',
          checked: true,
          order: 3,
        },
        {
          name: 'people_in_team',
          checked: true,
          order: 4,
        },
        {
          name: 'patents',
          checked: true,
          order: 5,
        },
        {
          name: 'sabbaticals',
          checked: true,
          order: 6,
        },
        {
          name: 'phds',
          checked: true,
          order: 7,
        },
        {
          name: 'courses',
          checked: true,
          order: 8,
        },
        {
          name: 'conferences',
          checked: true,
          order: 9,
        },
        {
          name: 'managerial',
          checked: true,
          order: 10,
        },
        {
          name: 'disseminations',
          checked: true,
          order: 11,
        },
        {
          name: 'stays-research',
          checked: true,
          order: 12,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      scientificStats: 'users/scientificStats',
      toggled: 'preview/toggled',
    }),
    fieldAux() {
      let aux = null
      if (!this.field) {
        aux = { name: 'Stats', tootlip: 'Stats' }
      } else {
        aux = this.field
      }
      return aux
    },
  },
  watch: {
    userId() {
      this.reloadData()
    },
    yearBetween() {
      this.reloadData()
    },
    toggled() {
      if (this.toggled !== this.isToggled) {
        this.isToggled = this.toggled
      }
    },
  },
  async mounted() {
    this.reloadData()
  },
  methods: {
    async reloadData() {
      this.loading = true
      if (this.setDefaultToggled != null) {
        this.isToggled = this.setDefaultToggled
      }
      if (this.userId) {
        await this.$store.dispatch('users/fetchUserScientificStats', { userId: this.userId, yearBetween: this.yearBetween })
      } else {
        await this.$store.dispatch('users/fetchUserScientificStats', { yearBetween: this.yearBetween })
      }
      this.loading = false
    },
  },
}
</script>
