<template>
  <div
    v-if="showAll || (!showAll && !hiddedToResearchers.includes(type))"
    :class="!showAll && !hiddedToResearchers.includes(type) ? 'col-md-3' : 'col-md-2'"
  >
    <div class="card">
      <div class="card-header pb-0">
        <h5>{{ title }}</h5>
      </div>
      <div class="card-body">
        <p
          v-if="num != 'disabled'"
          class="display-7 mb-0"
        >
          {{ num }}
        </p>
        <span
          v-else
          :id="`info-no-available`"
          class="badge bg-danger"
        >
          Not available <i data-feather="info" />
        </span>
        <b-tooltip
          triggers="hover"
          :target="`info-no-available`"
        >
          <span>You can only see this stat if you filter by a specific year.</span>
        </b-tooltip>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import { BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTooltip,
  },
  props: {
    type: { type: String, required: true, default: () => '' },
    num: { type: [Number, String], required: true, default: () => 0 },
    showAll: { type: Boolean, required: false, default: () => true },
    currentYear: { type: [String, Number], required: false, default: () => new Date().getFullYear() },
  },
  data() {
    return {
      title: '',
      hiddedToResearchers: [
        'sabbaticals',
        'patents',
        'stays-research',
        'disseminations',
      ],
    }
  },
  mounted() {
    switch (this.type) {
      case 'conferences':
        this.title = 'Conferences, workshops and invited talks'
        break
      case 'courses':
        this.title = 'Courses and Seminars'
        break
      case 'disseminations':
        this.title = 'Disseminations'
        break
      case 'grants':
        this.title = 'Grants & Resources'
        break
      case 'managerial':
        this.title = 'Managerial Activities'
        break
      case 'patents':
        this.title = 'Technology transfer'
        break
      case 'phds':
        this.title = 'PhD, Master Theses and TFG'
        break
      case 'publications':
        this.title = 'Publications'
        break
      case 'stays-research':
        this.title = 'Stays of Research'
        break
      case 'sabbaticals':
        this.title = 'Sabbaticals'
        break
      case 'amount_of_grant':
        this.title = `Amount for ${this.currentYear}`
        break
      case 'people_in_team':
        this.title = `People in your team (${this.currentYear})`
        break
      default:
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
}
</script>
